<template>
  <v-dialog v-if="!joined && !isLoggedIn" v-model="dialog" max-width="900px" style="max-height: 500px!important">
    <v-card class="newsletter-popup">
      <v-row style="margin: 0;" >
        <v-col cols="12" md="6" style="padding: 0; position: relative;">
          <!-- Close icon -->
          
          
          <!-- Image with overlay text -->
          <div class="newsletter-overlay">
            <h1 class="overlay-text">Don't<br>Miss<br>Best<br>Deals</h1>
            <h1 class="overlay-text-the">The</h1>
          </div>
          <img src="https://images.unsplash.com/photo-1500468756762-a401b6f17b46?q=80&w=988&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Newsletter Background" class="newsletter-background">
        </v-col>

        <v-col cols="12" md="6" style="display:flex">
          <v-icon style="position: absolute; top: 10px; right: 10px; font-size: 24px; cursor: pointer; color: black;z-index: 100;" @click="close()">mdi-close</v-icon>
          <!-- Content -->
          <div class="content" v-if="!justjoined">
            <div class="text">
              <h2 class="display-1 mb-0" style="    font-weight: bold;
    margin-bottom: 25px !important;
    font-size: 40px !important;">Get the latest<br>deals and more.</h2>
              <p class="subtitle-1 font-weight-thin">Sign up to receive a <strong>10% coupon code</strong> at checkout towards your first {{ company }} purchase.</p>
            </div>
            <v-text-field prepend-inner-icon="email" v-model="email" label="Enter your email address" outlined hide-details required></v-text-field>
            <div class="actions">
              <v-btn rounded x-large block color="primary" @click="joinNow">JOIN NOW</v-btn>
              <br><span style="text-decoration: underline; color: grey; cursor: pointer;" @click="close">Thank you, I already joined</span>
            </div>
          </div>

          <div class="content" v-else>
            <div class="text">
              <h2 class="display-1 mb-0" style="    
                font-weight: bold;
                margin-bottom: 25px !important;
                font-size: 40px !important;">
                Thank you!
              </h2>
              <p class="subtitle-1 font-weight-thin">Thank you for signing up! Check your inbox soon for a free promotion.</p>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookies from 'js-cookie';
import { mapState } from "vuex";
export default {
  data() {
    return {
      email: "",
      justjoined: false,
      joined: Cookies.get('joinedNewsletter') === 'true'
    }
  },
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    close: {
      type: Function,
      required: true
    },
    save: {
      type: Function,
      required: true
    },
    company: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.isLoggedIn,
    }),
  },
  methods: {
    joinNow() {
      if(!this.email) return;
      // Save email and close dialog
      this.save(this.email);
      this.justjoined = true;
      Cookies.set('joinedNewsletter', 'true', { expires: 365 });
    },
  }
};
</script>

<style scoped>

@media  only screen and (max-width:960px){

  .newsletter-background {
    height: 250px !important;
  }
  .overlay-text {
    font-size: 60px!important;
  }
  .overlay-text-the {
    font-size: 45px!important;
  }
  
}

.newsletter-popup {
  text-align: center;
}

.content {
  position: relative;
  z-index: 1;
  color: #242424;
  padding: 20px;
  margin: auto;
}


.overlay-text {
  font-size: 85px;
  font-weight: bold;
  color: white;
  text-align: center;
  line-height: 0.9;
  font-family: system-ui;
}

.overlay-text-the{
  font-family: cursive;
    font-size: 70px;
    color: darkturquoise;
    top: 46%;
    line-height: 0.9;
    position: absolute;
}

.newsletter-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  padding: 20px; /* Adjust padding as needed */
  z-index: 2; /* Ensure overlay is above image */
}

.headline {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 10px;
}

.description {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.actions {
  margin-top: 20px;
}

.actions v-btn {
  margin: 0 10px;
}

/* Image styles */
.newsletter-background {
  max-width: 100%;
  height: auto;
  max-height: 500px; /* Maximum height */
  width: 100%; /* Ensure image fills its container */
  object-fit: cover; /* Cover entire container */
  object-position: center; /* Center the image */
  position: relative; /* Ensure image stays behind overlay */
  z-index: 1; /* Ensure image is behind overlay */
}
</style>

