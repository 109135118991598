import Vue from "vue";
import Vuex from "vuex";
// import { appData } from "./appData";
import VuexPersistence from 'vuex-persist';
import Crypto from 'crypto-js';
import Cookie from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
import api from '../api';
import axios from 'axios';
import isEqual from 'lodash/isEqual';
import { event as gtagEvent } from 'vue-gtag';

Vue.use(Vuex);

const cookieName = 'appToken';
const storageKey = 'appState';
const encryptionToken = Cookie.get(cookieName) || uuidv4();
// Store the encryption token in a secure cookie.
Cookie.set(cookieName, encryptionToken, { secure: true, expires: 180 });

const vuexLocal = new VuexPersistence({
  storage: {
    getItem: () => {
      // Get the store from local storage.
      const store = window.localStorage.getItem(storageKey);

      if (store) {
        try {
          // Decrypt the store retrieved from local storage
          // using our encryption token stored in cookies.
          const bytes = Crypto.AES.decrypt(store, encryptionToken);

          return JSON.parse(bytes.toString(Crypto.enc.Utf8));
        } catch (e) {
          // The store will be reset if decryption fails.
          window.localStorage.removeItem(storageKey);
        }
      }

      return null;
    },
    setItem: (key, value) => {
      // Encrypt the store using our encryption token stored in cookies.
      const store = Crypto.AES.encrypt(value, encryptionToken).toString();

      // Save the encrypted store in local storage.
      return window.localStorage.setItem(storageKey, store);
    },
    removeItem: () => window.localStorage.removeItem(storageKey),
  },
});

export default new Vuex.Store({
  state: {
    isLoggedIn: false,
    user: null,
    currencyCode: "CA",
    appData: null,
    tokenExpiry: null,
    cartItems: [],
  },
  getters: {
    isLoggedIn: (state) => state.isLoggedIn,
    getTokenExpiry: (state) => state.tokenExpiry,
    getCartItems: (state) => state.cartItems,
    getNumberOfCartItems: (state) => state.cartItems.length,
    isAdmin: (state) => state.user?.isAdmin,
    getAppData: (state) => state.appData,
  },
  mutations: {
    login(state, { user, tokenExpiry }) {
      state.isLoggedIn = true;
      state.user = user;
      state.tokenExpiry = tokenExpiry;
      gtagEvent('login', { method: 'Google' })
    },
    refresh(state, tokenExpiry) {
      state.tokenExpiry = tokenExpiry;
    },
    verify(state) {
      state.user.isVerified = true;
    },
    logout(state) {
      state.isLoggedIn = false;
      state.user = null;
    },
    saveAppData(state, appData) {
      state.appData = appData;
    },
    alreadyInCart(state, product){
      return state.cartItems.some(ci => { 
        let a = {...product};
        delete a.qty;
        let b = {...ci}
        delete b.qty;
        return isEqual(a, b)});
    },
    incrementCartItemQty(state, cartItem) {
      cartItem.qty++;
    },
    decrementCartItemQty(state, cartItem) {
      if (cartItem.qty > 1) cartItem.qty--;
    },
    addCartItem(state, product) {
      gtagEvent('add_to_cart', { method: 'Google' })
      const { qty, ...productWithoutQty } = product; // Destructure to get product without qty

      const foundIndex = state.cartItems.findIndex(ci => {
        const { qty: ciQty, ...ciWithoutQty } = ci; // Destructure to get ci without qty
        return isEqual(ciWithoutQty, productWithoutQty);
      });
      
      if (foundIndex !== -1) {
        for(let i =0; i < product.qty; i++){
          this.commit("incrementCartItemQty", state.cartItems[foundIndex]);
        }
       
      } else {
        state.cartItems.push(product); // Add new product with qty 1
      }
    },
    removeCartItem(state, cartItem) {
      gtagEvent('remove_from_cart', { method: 'Google',event_name:'remove_from_cart' })
      const { qty, ...cartItemWithoutQty } = cartItem;
      let cartItemIndex = state.cartItems.findIndex(ci => {
        const { qty: ciQty, ...ciWithoutQty } = ci; // Destructure to get ci without qty
        return isEqual(ciWithoutQty, cartItemWithoutQty);
      });

      state.cartItems.splice(cartItemIndex, 1)

    },
    clearCart(state) {
      state.cartItems = [];
    }
  },
  actions: {
    async fetchAppData({ commit }) {
      try {
        const jsonData = (await api.get('/api/app-data')).data;
        commit('saveAppData', jsonData.appData);
      } catch (err) {
        alert(JSON.stringify(err.response.data.error))
      }
    },
    async syncAppData({ commit }, payload) {
      // console.log(payload)
      return (await api.post("/api/app-data", { appData: payload }).then((resp) => {
        commit('saveAppData', payload);
        return resp;
      }).catch(err => {
        console.log(err)
        alert(JSON.stringify(err?.response?.data?.error))
      }))
    },
    async refresh({ commit }) {
      const baseURL = process.env.NODE_ENV === 'production'
      ? process.env.VUE_APP_BACKEND_BASE_URL // Production base URL
      : ''; // Empty string for development (assuming proxy is handling it)

      const newTokenExpiry = (await axios.get('/api/auth/refresh',{
          headers: {
            'Content-Type': 'application/json',
            'accept': '*/*',
            'x-schema': process.env.VUE_APP_SCHEMA
          },
         withCredentials: true,
         baseURL
        }
      )).data.access_token_expiry;
      commit('refresh', newTokenExpiry);
    }
  },
  modules: {},
  plugins: [vuexLocal.plugin],
});
