<template>
  <div>
    <!-- <v-app full-screen style="position: absolute;
    top: 0;
    left: 0;
    height: 100vh; width:100vw;">
    
  </v-app> -->

    <v-app id="inspire">
      <v-navigation-drawer width="70vw" style="max-width: 400px" temporary fixed app v-model="drawer"
        :clipped-right="true">
        <template v-slot:prepend>

          <v-list-item two-line>
            <v-list-item-avatar v-if="isLoggedIn">
              <img
                :src="`https://ui-avatars.com/api/?background=${$vuetify.theme.currentTheme.primary.replace('#', '')}&color=${textColor.replace('#', '')}&rounded=true&name=${user.firstName + '%20' + user.lastName}`" />
            </v-list-item-avatar>

            <v-list-item-content v-if="isLoggedIn">
              <v-list-item-title>{{ user.firstName + " " + user.lastName }}</v-list-item-title>
              <v-list-item-subtitle>Logged In</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-content v-else>
              <v-btn rounded block color="primary" to="/signup" style="margin-bottom:10px;">
                Sign Up
              </v-btn>
              <br>
              <v-btn rounded block to="/login">
                Login
              </v-btn>
              <br>
            </v-list-item-content>

          </v-list-item>
        </template>
        <v-divider></v-divider>

        <v-list dense nav>
          <v-list-item link v-for="item in isAdmin ? adminLinks : customerLinks" :to="item.link" :key="item.title"
            style="text-align: left;">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <template v-slot:append>
          <v-btn color="primary" v-if="isLoggedIn" block to="/logout" @click="userLogout()">
            Logout
          </v-btn>
          <br>
          <v-divider></v-divider>
          <v-switch style="padding-bottom:50px;" :label="$vuetify.theme.dark ? 'Dark Mode' : 'Light Mode'" inset
            @click="$vuetify.theme.dark = !$vuetify.theme.dark"></v-switch>
         
        </template>
      </v-navigation-drawer>


      <!-- search drawer -->
      <v-navigation-drawer style="max-width: 475px; padding-right: 0px;" width="100vw" temporary fixed app clipped right
        v-model="searchDrawer" :clipped-right="false">
        <template v-slot:prepend>
          <v-list-item>
            <div style="margin:0px 0px -15px -15px; width:100%;">
              <div>
                <v-text-field color="primary" label="Explore"
                  v-model="searchText"
                  @input="debounceSearch"
                  clearable filled rounded dense>
                  <template v-slot:prepend-inner>
                      <v-icon>mdi-magnify</v-icon>
                  </template>
                  <template v-slot:append-outer>
                    <v-btn plain color="primary" style="padding: 8PX;margin-top: -4px;"
                      @click.stop="searchDrawer = false"> Cancel</v-btn>
                  </template>
                </v-text-field>
              </div>
            </div>

          </v-list-item>
        </template>

        <!-- Divider after search textfield -->
        <v-divider style="margin-right: 30px;"></v-divider>


        <!-- Autocomplete items -->
        <v-list style="text-align:left;" v-if="autocompleteItems.length > 0 && (searchText?.length != 0 && searchText!=null)">
          <v-list-item v-for="(item, index) in autocompleteItems" :key="index">
            <router-link style="display:contents; cursor:pointer;" :to="`/product?id=${item.id}`" tag="div">
              <v-list-item-avatar>
              <v-img :src="item.imgUrl" max-width="40" max-height="40"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title >{{ item.name }}</v-list-item-title>
            </v-list-item-content>

            </router-link>
           
          </v-list-item>
        </v-list>

        <template v-else>
              
          <div v-if="previousSearch.length > 0">
            <br>
            <h3 class="subtitle-1" style="text-align:left">Previous Searches</h3>
            <v-list>
              <v-list-item
              style="text-align: left;
                padding-right: 30px;"
               v-for="(searchItem, i) in previousSearch" :key="i">

                <v-list-item-icon>
                  <v-icon>mdi-history</v-icon>
                </v-list-item-icon>

                <v-list-item-title>
                  {{ searchItem.searchText }}
                </v-list-item-title>
                
                  <v-list-item-icon>
                    <v-icon @click="previousSearch.splice(i, 1)">mdi-close</v-icon>
                  </v-list-item-icon>
                
              </v-list-item>
            </v-list>
          </div>


          <div v-else style="height:100%">
            <v-row class="fill-height" align="center" justify="center">
              <div style="padding-right:30px;">
                <v-icon>mdi-magnify</v-icon>
                <p>No Results.</p>
              </div>
            </v-row>
          </div>

        </template>
        

      </v-navigation-drawer>

      <!-- cart menu drawer -->
      <v-navigation-drawer style="max-width: 475px; padding-right:0px;" width="100vw" temporary fixed app
        id="bag-sidebar" clipped right v-model="cartDrawer" :clipped-right="false">
        <template v-slot:prepend>
          <v-list-item style="padding-right: 30px;">
            <div style="margin-left:-15px">
              <div>
                <p class="headline text-bold mb-0">
                  Shopping Bag &nbsp;
                </p>
              </div>
            </div>
            <v-btn style="margin-left:auto;" icon @click.stop="cartDrawer = !cartDrawer">
              <v-icon>mdi-close</v-icon></v-btn>
          </v-list-item>
        </template>

        <v-divider style="margin-right:30px;"></v-divider>
        <span class="subtitle-2" style="display:flex;padding-right:30px;">
          <v-icon color="text-bold primary" style="font-size:14px">mdi-shopping</v-icon> &nbsp;
          <div><strong class=" primary--text">{{ numberOfCartItems }} Items</strong> in your bag.</div>
        </span>
        <br>

        <!-- free shipping progress bar -->
         <div style="padding-right:69px; position:relative;">
          <v-progress-linear
          color="primary"
          height="10"
          :value="(subtotal/appData.shippingPolicy.freeShipping.minimumAmount) * 100"
          striped
        ></v-progress-linear>
        <div class="primary" style="
          width:50px; 
          height: 50px; 
          border-radius: 50%; 
          display:flex; 
          align-items:center; 
          justify-content:center;
          right: 20px;
          top: -20px;
          position:absolute;">
          <v-icon :light="!themedTextColor('primary')" :dark="themedTextColor('primary')">mdi-truck</v-icon>
        </div>
        <span v-if="((subtotal/appData.shippingPolicy.freeShipping.minimumAmount) * 100) < 100">${{(appData.shippingPolicy.freeShipping.minimumAmount - subtotal).toFixed(2)}} Until Free Shipping</span>
        <span v-else>Free Shipping Unlocked!</span>
      </div>
       

        <v-list v-if="cartItems.length > 0" style="min-width: 300px; padding-right:30px;">
          <template v-for="(cartItem, i) in cartItems">
            <v-list-item v-bind:key="i" :ripple="false" style="
                  border-radius: 15px; margin-bottom:10px; padding: 0px 6px;">
              <!-- <router-link :to="`/product?id=${cartItem.id}`"> -->
              <v-badge stacked left offset-x="15px" offset-y="15px" color="#808080a3">
                <div slot="badge" @click="removeCartItem(cartItem)" style="cursor:pointer">
                  <v-icon>mdi-close</v-icon>
                </div>
                <v-img style="width: 86px; height: 86px; border-radius:20%; box-shadow: #d3d3d380 0px 0px 12px;"
                  :src="cartItem.metadataGroups.length == 1 ? cartItem.metadataGroups[0].metadataOptions.find(mi => mi.id == cartItem.metadataGroups[0].defaultOptionId).img : cartItem.imgUrl"></v-img>
              </v-badge>
              <!-- </router-link> -->

              <v-list-item-content style="
                  padding-left: 17px;
                  align-items: baseline;
                  flex-direction: column;
                  display: flex;
                ">
                <router-link tag='span' class="subtitle-1 text-bold" :to="`/product?id=${cartItem.id}`"
                  style="margin-bottom: 0px; cursor:pointer; line-height:normal; text-align: left;">
                  <strong> {{ truncate(cartItem.name, 41, '...') }}</strong>
                </router-link>

                <span class="subtitle-2" style="text-align:left">
                  <template
                    v-for="(meta, i) of cartItem.metadataGroups.filter(grp => !(grp.isOptional && !grp.defaultOptionId))">
                    {{
        (meta.metadataOptions.find(
          (o) => o.id == meta.defaultOptionId
        )?.title ?? "") + ((cartItem.metadataGroups).length != (i + 1) ? "," : "")
      }}
                  </template>
                </span>
                <div style="width: 100%;
                  display: flex;
                  justify-content: space-between;">

                  <span class="primary--text mt-2"><strong>${{
        (
          ((cartItem.price - (cartItem.price * cartItem.salePctOff)) +
            selectedMetadataOptionsTotal(cartItem))
        ).toFixed(2)
      }}</strong></span>


                  <div class="primary--text quantity-selector">
                    <v-btn icon @click="decrementCartItemQty(cartItem)">
                      <span class="primary primary--text" style="
                      border-radius:50%; 
                      width: 23px; 
                      font-size:16px;
                      line-height:9px;
                      height: 23px;
                      background-color:transparent!important;
                      border: 1px solid; 
                        ">
                        _
                      </span>
                    </v-btn>
                    <span style="margin: 0px 3px;">{{ cartItem.qty }}</span>
                    <v-btn icon @click="incrementCartItemQty(cartItem)">
                      <span class="primary" style="
                      border-radius:50%; 
                      width: 23px; 
                      height: 23px;
                      font-size:17px;
                      color:white;">
                        +
                      </span>
                    </v-btn>
                  </div>


                </div>

                <!-- <div style="bottom: 10px; right: 10px; top:30px; position: absolute">
                  ${{
                    (
                      ((cartItem.price - (cartItem.price * cartItem.salePctOff)) + selectedMetadataOptionsTotal(cartItem)) *
                      cartItem.qty
                    ).toFixed(2)
                  }}
                </div> -->
              </v-list-item-content>
            </v-list-item>
            <!-- ${{((cartItem.price + selectedMetadataOptionsTotal(cartItem) - ((cartItem.price + selectedMetadataOptionsTotal(cartItem)) * cartItem.salePctOff))).toFixed(2)}} -->
          </template>
        </v-list>


        <div v-else style="height:100%">
          <v-row class="fill-height" align="center" justify="center">
            <div>
              <img src="../assets/img/shopping-bag.svg" />
              <p>Your Bag is empty.</p>
            </div>

          </v-row>

        </div>

        <v-divider style="margin-right:30px;"></v-divider>



        <template v-slot:append v-if="numberOfCartItems > 0">
          <v-divider style="margin-right:30px"></v-divider>
          <div style="padding:0px 30px 10px 0px;">
            <div v-if="cartItems.length > 0"
              style="text-align: right; padding: 5px 10px 0px;display: flex; justify-content: space-between;">
              <p class="headline font-weight-light pt-3">Subtotal: </p>
              <p class="headline font-weight-light pt-3"> ${{ subtotal }}</p>

            </div>

            <!-- <v-switch :label="$vuetify.theme.dark ? 'Dark Mode' : 'Light Mode'" inset @click="$vuetify.theme.dark = !$vuetify.theme.dark"></v-switch> -->
            <v-btn color="primary" rounded x-large block to="/checkout" @click="cartDrawer = false">
              <v-icon>mdi-shopping</v-icon> &nbsp;
              Checkout
              ({{ numberOfCartItems }})
            </v-btn>

            <img src="../assets/img/payment-methods.png" style="height: 50px; width: 250px" />
          </div>

        </template>
      </v-navigation-drawer>

      <v-app-bar v-if="notCheckout" :height="65" :clipped-left="$vuetify.breakpoint.lgAndUp" app
        :style="{ 'paddingTop': showBanner ? '40px' : 'unset', 'paddingBottom': '65px' }"
        color="secondary" :light="!themedTextColor('secondary')" :dark="themedTextColor('secondary')">
        <template v-if="showBanner">
          <v-banner v-if="appData.banner.enabled && (!isLoggedIn || (user && user.isVerified))" 
            style="position: absolute; top:-40px; width:100%; left:0;" single-line elevation="3"
            color="primary" :dark="true">
            <v-icon v-if="appData.banner.icon">{{ appData.banner.icon }}</v-icon> &nbsp; &nbsp;
            {{ appData.banner.text }}

            <template v-slot:actions v-if="appData.banner.actionText">
              <v-btn text :style="{ color: `${appData.accentBackgroundColor}!important`, 'padding': '0px' }" :to="`${appData.banner.actionLink}`">
                {{appData.banner.actionText}}
              </v-btn>
            </template>
          </v-banner>

          <v-banner v-else style="position: absolute; top:-40px; width:100%; left:0;" single-line elevation="3" icon="$warning"
            color="primary" :dark="true">
            Your account has not yet been verified. Please verify now to receive
            access to all features

            <template v-slot:actions>
              <v-btn text :style="{ color: `${appData.accentBackgroundColor}!important`, 'padding': '0px' }" to="/verify">
                Verify
              </v-btn>
            </template>
          </v-banner>

        </template>
       
        <v-app-bar-nav-icon @click="drawer = !drawer" />
        <v-spacer />
        <v-toolbar-title style="width: 350px">
          <router-link to="/" style="text-decoration: none"><img :src="themedLogo()"
              style="height: 60px; margin-top: 5px" /></router-link>
        </v-toolbar-title>
        <!-- <v-text-field hide-details solo underlined prepend-inner-icon="mdi-magnify" label="Search"
          class="hidden-sm-and-down pl-10 ml-4" /> -->
        <v-spacer />
        <v-btn icon @click="searchDrawer = !searchDrawer">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <!-- <v-menu open-on-hover offset-y>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon>
            <v-icon>mdi-search</v-icon>
          </v-btn>
        </template>
        <v-card class="mx-auto" max-width="344" outlined>
          <div v-if="isLoggedIn">
            <v-list-item to="/account" active-class="">
              <v-list-item-title>Account</v-list-item-title>
            </v-list-item>
            <v-list-item to="/logout" @click="userLogout()" active-class="">
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </div>
          <div v-else>
            <v-list-item to="/login" active-class="">
              <v-list-item-title>Login</v-list-item-title>
            </v-list-item>
            <v-list-item to="/signup" active-class="">
              <v-list-item-title>Sign Up</v-list-item-title>
            </v-list-item>
          </div>
        </v-card>
      </v-menu> -->

        <!-- <v-btn v-on="on" icon>
        <v-badge
          content="2"
          value="2"
          color="green"
          overlap
        >
          <v-icon>mdi-bell</v-icon>
        </v-badge>
      </v-btn> -->
        <!-- <CartMenu></CartMenu> -->
        <v-btn icon @click="cartDrawer = !cartDrawer" class="mr-3">
          <v-badge :content="numberOfCartItems" :value="numberOfCartItems" color="primary">
            <v-icon>mdi-shopping</v-icon>
          </v-badge>
        </v-btn>

      </v-app-bar>

      <v-app-bar v-if="!notCheckout" :height="65" :clipped-left="$vuetify.breakpoint.lgAndUp" app
        :style="{ 'paddingTop': showBanner ? '40px' : 'unset', 'paddingBottom': '65px' }"
        color="secondary" :light="!themedTextColor('secondary')" :dark="themedTextColor('secondary')">
           <template v-if="showBanner">
          <v-banner v-if="appData.banner.enabled && (!isLoggedIn || (user && user.isVerified))" 
            style="position: absolute; top:-40px; width:100%; left:0;" single-line elevation="3"
            color="primary" :dark="true">
            <v-icon v-if="appData.banner.icon">{{ appData.banner.icon }}</v-icon> &nbsp; &nbsp;
            {{ appData.banner.text }}

            <template v-slot:actions v-if="appData.banner.actionText">
              <v-btn text :style="{ color: `${appData.accentBackgroundColor}!important`, 'padding': '0px' }" :to="`${appData.banner.actionLink}`">
                {{appData.banner.actionText}}
              </v-btn>
            </template>
          </v-banner>

          <v-banner v-else style="position: absolute; top:-40px; width:100%; left:0;" single-line elevation="3" icon="$warning"
            color="primary" :dark="true">
            Your account has not yet been verified. Please verify now to receive
            access to all features

            <template v-slot:actions>
              <v-btn text :style="{ color: `${appData.accentBackgroundColor}!important`, 'padding': '0px' }" to="/verify">
                Verify
              </v-btn>
            </template>
          </v-banner>

        </template>
       
        <v-toolbar-title style="width: 350px">
          <router-link to="/" style="text-decoration: none"><img :src="themedLogo()"
              style="height: 60px; margin-top: 5px" /></router-link>
        </v-toolbar-title>

        <v-spacer />
      </v-app-bar>

      <JoinNewsletterDialog :company="appData.title" :save="joinNewsletter" :dialog="newsletterDialog"
        :close="() => newsletterDialog = false"></JoinNewsletterDialog>
      <router-view :style="{ 'paddingTop': showBanner? '105px' : '60px' }" />

      <v-card class="accent">
        <v-container>
          <v-row no-gutters>
            <v-col class="col-12 col-md-4 col-sm-12">
              <v-row>
                <v-col class="col-12 col-sm-3 pr-4" align="center">
                  <v-icon class="display-2">mdi-truck</v-icon>
                </v-col>
                <v-col class="col-12 col-sm-9 pr-4" v-if="appData.shippingPolicy.freeShipping.enabled">
                  <h3 class="font-weight-light">FREE SHIPPING & RETURNS</h3>
                  <p class="font-weight-thin">Free Shipping{{ appData.shippingPolicy.freeShipping.minimumAmount > 0 ? ` over $${appData.shippingPolicy.freeShipping.minimumAmount}`  : ' on all orders' }}</p>
                </v-col>
                <v-col v-else class="col-12 col-sm-9 pr-4">
                  <h3 class="font-weight-light">FREE RETURNS</h3>
                  <p class="font-weight-thin">Free Returns within 30 days</p>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="col-12 col-md-4 col-sm-12">
              <v-row>
                <v-col class="col-12 col-sm-3 pr-4" align="center">
                  <v-icon class="display-2">mdi-cash-refund</v-icon>
                </v-col>
                <v-col class="col-12 col-sm-9 pr-4">
                  <h3 class="font-weight-light">MONEY BACK GUARANTEE</h3>
                  <p class="font-weight-thin">30 Days Money Back Guarantee</p>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="col-12 col-md-4 col-sm-12">
              <v-row>
                <v-col class="col-12 col-sm-3 pr-4" align="center">
                  <v-icon class="display-2">mdi-lock</v-icon>
                </v-col>
                <v-col class="col-12 col-sm-9 pr-4">
                  <h3 class="font-weight-light">SSL Secure</h3>
                  <p class="font-weight-thin">Safe and Secure Shopping</p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card>

      <v-footer class="footer" padless style="padding-top: 32px">
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="3">
              <h3>Customer Service</h3>
              <v-list dense>
                <v-list-item v-for="item in customerServiceLinks" :key="item.title">
                  <v-list-item-title>
                    <router-link :to="item.link">{{ item.title }}</router-link>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <h3>Company Information</h3>
              <v-list dense>
                <v-list-item v-for="item in companyInfoLinks" :key="item.title">
                  <v-list-item-title>
                    <router-link :to="item.link">{{ item.title }}</router-link>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <h3>Account</h3>
              <v-list dense>
                <v-list-item v-for="item in accountLinks" :key="item.title">
                  <v-list-item-title>
                    <router-link :to="item.link">{{ item.title }}</router-link>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <h3>Stay In Touch</h3>
              <v-form>
                <v-text-field dense v-model="newsletterEmail" label="Email" type="email" required>
                  <template #append>
                    <v-btn style="border-radius: 0px !important" color="primary" @click="joinNewsletter">Join</v-btn>
                  </template>
                </v-text-field>
              </v-form>
              <h3>Follow Us</h3>
              <v-list class="social-links" dense>
                <div style="display: inline-flex">
                  <i v-for="(item, i) in appData?.social" :key="i" style="margin: 5px">
                    <a :href="item.link">
                      <v-icon :id="item.icon" class="footer-icon" color="primary">{{ item.icon }}</v-icon>
                    </a>
                  </i>
                </div>
              </v-list>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </v-container>
        <v-card flat tile width="100%" color="secondary" :light="!themedTextColor('secondary')"
          :dark="themedTextColor('secondary')">
          <v-card-text>
            <v-btn class="mx-4" icon href="/">
              <v-icon size="24px">mdi-home</v-icon>
            </v-btn>
            <v-btn class="mx-4" icon :href="`mailto:${appData.contact.email}`">
              <v-icon size="24px">mdi-email</v-icon>
            </v-btn>
            <v-btn class="mx-4" icon :href="`tel:${appData.contact.phone}`">
              <v-icon size="24px">mdi-phone</v-icon>
            </v-btn>
          </v-card-text>

          <v-card-text class="pt-0">
            Contact us by phone or email for any inquiries and we'll get back to
            you as soon as possible. {{ appData.title }} prides ourselves on
            providing excellent customer service or assistance regarding any
            inquiry or questions that you may have. Contact us via our 24/7 phone
            number {{ appData.contact.phone }} by call or text, or alternatively
            by email
            {{ appData.contact.email }}

            <v-divider></v-divider>

            <br>
            <div class="disclaimer">
              {{ appData.footer.disclaimer }}
            </div>

            <div class="linear-list">
              <ul>
                <li v-for="item in linearLinks" :key="item.title">
                  <router-link :to="item.link">
                    {{ item.title }}
                  </router-link>
                </li>
              </ul>
            </div>
          </v-card-text>



          <v-card-text>
            <v-icon>mdi-copyright</v-icon> &nbsp;{{
        new Date().getFullYear()
      }}
            — <strong>{{ appData.title }}</strong>
          </v-card-text>
        </v-card>
      </v-footer>

    </v-app>
  </div>
</template>
<style scoped lang="scss">
.shopping-bag {
  font-size: 30px !important;
  left: -11px;
  top: -2px;
}

.quantity-selector {
  display: flex;
  align-items: center;
  line-height: normal;
  margin: -10px 5px -16px 0px;
}

.footer {

  h3 {
    font-size: 1.2rem;
    font-weight: 500;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    margin-bottom: 8px;
  }

  a {
    // color: #333;
    text-decoration: none;
  }

  a:hover {
    // color: #000;
    text-decoration: underline;
  }

  .disclaimer {
    font-size: 0.8rem;
    margin-bottom: 16px;
  }

  .linear-list {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .linear-list ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .linear-list li {
    margin: 0 8px;
  }
}
</style>
<script>
import { mapState, mapMutations, mapGetters } from "vuex";
import debounce from 'lodash/debounce'
import api from "@/api";
import CartMenu from './cartMenu.vue';
import JoinNewsletterDialog from './dialogs/joinNewsletterDialog.vue'
export default {
  components: {
    CartMenu,
    JoinNewsletterDialog
  },
  data() {
    return {
      searchDrawer: false,
      searchText: '',
      newsletterDialog: true,
      autocompleteItems: [],
      activeBtn: 1,
      categories: [],
      previousSearch: [],
      customerServiceLinks: [
        // { title: "FAQ", link: "/faq" },
        { title: "Shop", link: "/shop" },
        { title: "Contact Us", link: "/contact" },
        { title: "Checkout", link: "/checkout" },
        { title: "Refunds, Resends & Returns", link: "returns-policy" },
      ],
      accountLinks: [
        { title: "My Account", link: "/account" },
        { title: "Login", link: "/login" },
        { title: "Create an Account", link: "/signup" },
        { title: "Admin Login", link: "/admin/login" },
      ],
      companyInfoLinks: [
        { title: "About Us", link: "#" },
        { title: "Cookie Policy", link: "/cookie-policy" },
        { title: "Privacy Policy", link: "/privacy-policy" },
        { title: "Terms of Use", link: "/terms-of-use" },
      ],
      linearLinks: [
        { title: "Privacy Policy", link: "/privacy-policy" },
        { title: "Terms of Use", link: "/terms-of-use" },
        { title: "Cookie Policy", link: "/cookie-policy" },
      ],
      textColor: '#FFF',
      newsletterEmail: "",
      drawer: false,
      cartDrawer: false,
      adminLinks: [
        { title: "Overview", icon: "mdi-monitor-dashboard", link: "/admin/dashboard" },
        { title: "Account", icon: "mdi-account", link: "/account" },
        { title: "Orders", icon: "mdi-order-bool-descending", link: "/admin/orders" },
        { title: "Transactions", icon: "mdi-currency-usd", link: "/admin/transactions" },
        { title: "Products", icon: "mdi-square-edit-outline", link: "/admin/products" },
        { title: "Files", icon: "mdi-file", link: "/admin/files" },
        { title: "Promos", icon: "mdi-label-percent", link: "/admin/promos" },
        { title: "Theme", icon: "mdi-format-color-fill", link: "/admin/theme" },
        { title: "Users", icon: "mdi-account-group-outline", link: "/admin/users" },
        { title: "Reviews", icon: "mdi-playlist-star", link: "/admin/reviews" },
        { title: "Newsletter", icon: "mdi-email-multiple-outline", link: "/admin/newsletter" },
        { title: "Logs", icon: "mdi-file-document-outline", link: "/admin/logs" },
        { title: "App Data", icon: "mdi-file-cog-outline", link: "/admin/app-data" },
        { title: "Deployment", icon: "mdi-cloud-upload-outline", link: "/admin/deployment" },
      ],
      customerLinks: [
        { title: "Home", icon: "mdi-home-city", link: "/" },
        { title: "Shop", icon: "mdi-shopping-outline", link: "/shop" },
        { title: "Account", icon: "mdi-account", link: "/account" },
        // { title: "Blog", icon: "mdi-newspaper-variant-multiple-outline", link: "/blog" },
        // { title: "Orders", icon: "mdi-order-bool-descending", link: "/orders" },
        { title: "Contact Us", icon: "mdi-headset", link: "/contact" },
        { title: "Track Shipping", icon: "mdi-map-marker", link: "/track-shipping" },
      ]
    };
  },
  methods: {
    ...mapMutations([
      "incrementCartItemQty",
      "decrementCartItemQty",
      "addCartItem",
      "removeCartItem",
      "logout",
      "alreadyInCart"
    ]),
    search(searchKey){
      this.previousSearch.push({searchText: searchKey})
    },
    debounceSearch: debounce(function (key) {
      if(!key) return;
      console.log("searching for", key)
      this.search(key);
      api.get(`/api/product/search/${key}`).then(resp=>{
        this.autocompleteItems = [...resp.data]
      });
    }, 1000), // Adjust debounce time as needed
    renderBackupFooterIcons() {
      const icons = document.querySelectorAll(".footer-icon");
      icons.forEach((icon, index) => {
        const iconStyles = window.getComputedStyle(icon, "::before");
        const content = iconStyles.getPropertyValue("content");
        const isValid = content !== "none" && content !== "normal";
        if (!isValid) {
          const defaultImageUrl = this.appData.social[index].iconUrl
          // Replace the icon element with the default image
          const img = document.createElement("img");
          img.src = defaultImageUrl;
          img.alt = "Default Image";
          img.width = 24;
          img.height = 24;
          icon.replaceWith(img);
        }
      });
    },
    minusQtyHandler(cartItem) {
      if (cartItem.qty > 0) this.decrementCartItemQty(cartItem)
      if (cartItem.qty == 0) this.removeCartItem(cartItem)
    },
    selectedMetadataOptionsTotal(cartItem) {
      let total = 0;
      cartItem?.metadataGroups?.forEach((meta) => {
        total += parseFloat(
          meta.metadataOptions.find(
            (option) => option.id == meta.defaultOptionId
          )?.price ?? 0
        );
      });
      return parseFloat(total.toFixed(2));
    },
    // changeQty(newQty, cartItem) {
    //   //not working yet
    //   let cartItem = this.alreadyInCart(cartItem)
    //   if (newQty == 0) {
    //     return this.removeCartItem(cartItemId);
    //   }
    //   if (newQty > cartItem.qty) {
    //     while (cartItem.qty != newQty) {
    //       this.incrementCartItemQty(cartItemId);
    //     }
    //   } else if (newQty < cartItem.qty) {
    //     while (cartItem.qty != newQty) {
    //       this.decrementCartItemQty(cartItemId);
    //     }
    //   }
    // },
    joinNewsletter(email = this.newsletterEmail) {
      api.post('/api/mail/join-newsletter', { email })
        .catch(e => console.log(e))
    },
    async userLogout() {
      await api.get("/api/auth/logout");
      this.logout();
    },
    async getCategories() {
      this.categories = (await api.get("/api/category")).data;
    },
    isDarkTheme() {
      return this.$vuetify.theme.dark;
    },
    themedTextColor(colorTheme) {
      const darkTheme = this.isDarkTheme();
      let override = colorTheme == 'primary' ? 'invertPrimaryText' : 'invertSecondaryText'
      const invertOverride = this.appData.theme[darkTheme ? 'dark' : 'light'][override];

      return invertOverride
        ? darkTheme
          ? false
          : true
        : darkTheme
          ? true
          : false
    },
    themedLogo() {
      const darkTheme = this.isDarkTheme();
      const invertOverride = this.appData.theme[darkTheme ? 'dark' : 'light'].invertSecondaryText;

      return invertOverride
        ? darkTheme
          ? this.appData.assetUrls.logo
          : this.appData.assetUrls.logoDark
        : darkTheme
          ? this.appData.assetUrls.logoDark
          : this.appData.assetUrls.logo;
    },
    handleThemeChange() {
      setTimeout(() => {

        const elementDark = document.querySelector('.theme--dark.v-label')
        const elementLight = document.querySelector('.theme--light.v-label')
        function rgba2hex(orig) {
          var a, isPercent,
            rgb = orig.replace(/\s/g, '').match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i),
            alpha = (rgb && rgb[4] || "").trim(),
            hex = rgb ?
              (rgb[1] | 1 << 8).toString(16).slice(1) +
              (rgb[2] | 1 << 8).toString(16).slice(1) +
              (rgb[3] | 1 << 8).toString(16).slice(1) : orig;

          if (alpha !== "") {
            a = alpha;
          } else {
            a = 0o1;
          }
          // multiply before convert to HEX
          a = ((a * 255) | 1 << 8).toString(16).slice(1)
          hex = hex + a;

          return hex;
        }
        if (!this.isDarkTheme()) {
          let element = elementDark ? elementDark : elementLight
          // console.log("rg to hex",rgba2hex(window.getComputedStyle(element).color))

          this.textColor = rgba2hex(window.getComputedStyle(element).color)
        } else if (this.isDarkTheme()) {
          let element = elementDark ? elementDark : elementLight
          // console.log("rg to hex",rgba2hex(window.getComputedStyle(element).color))

          this.textColor = rgba2hex(window.getComputedStyle(element).color)
        }
      }, 200)
    }
  },
  watch: {
    '$vuetify.theme': {
      deep: true, // Watch nested properties of the theme object
      handler: 'handleThemeChange',
    },
    'appData': {
      deep: true,
      handler: 'renderBackupFooterIcons'
    }
  },
  computed: {
    ...mapState({
      appData: (state) => state.appData,
      isLoggedIn: (state) => state.isLoggedIn,
      user: (state) => state.user,
    }),
    ...mapGetters({
      numberOfCartItems: "getNumberOfCartItems",
      cartItems: "getCartItems",
      isAdmin: "isAdmin"
    }),
    showBanner(){
      return (this.isLoggedIn && !this.user.isVerified) || 
      (this.appData.banner.enabled && (!this.isLoggedIn || (this.user && this.user.isVerified)))
    },  
    truncate() {
      return (text, length, clamp) => {
        clamp = clamp || '...';
        var node = document.createElement('div');
        node.innerHTML = text;
        var content = node.textContent;
        return content.length > length ? content.slice(0, length) + clamp : content;
      }
    },
    currentYear() {
      return new Date().getFullYear();
    },
    subtotal() {
      let total = 0;
      this.cartItems.forEach((cartItem) => {
        const itemTotal =
          ((cartItem.price - (cartItem.price * cartItem.salePctOff)) + this.selectedMetadataOptionsTotal(cartItem)) *
          cartItem.qty;
        total += parseFloat(itemTotal.toFixed(2));
      });
      return total.toFixed(2);
    },
    notCheckout() {
      return !this.$route.fullPath.includes('checkout');
    }
  },
  beforeMount() {
    this.getCategories();
    this.$vuetify.theme.dark = false;
  },
  mounted() {
    console.log("theme", this.$vuetify.theme)
    this.$nextTick(() => {
      this.renderBackupFooterIcons();
      const linkForFavicon = document.querySelector(
        `head > link[rel='icon']`
      );
      linkForFavicon.setAttribute(`href`, this.appData.assetUrls.favicon);
    });
  }
};
</script>
