import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import Layout from "@/components/LayoutContainer.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Layout,
    children: [
      {
        path: "/shop",
        component: () => import("@/views/ShopView.vue"),
        name: "Shop",
        meta: {
          title: "Shop",
        },
        beforeEnter: (to, from, next) => {
          const isAdmin = store.getters["isAdmin"];
          if (!isAdmin) {
            next();
          } else {
            next("/admin/products");
          }
        },
      },
      {
        path: "/login",
        component: () => import("@/views/LoginView.vue"),
        name: "Login",
        meta: {
          title: "Login",
        },
      },
      {
        path: "/logout",
        component: () => import("@/views/LoginView.vue"),
        name: "Logout",
        meta: {
          title: "Successful Logout",
        },
      },
      {
        path: "/contact",
        component: () => import("@/views/ContactView.vue"),
        name: "Contact",
        meta: {
          title: "Contact Us",
        },
      },
      {
        path: "/",
        alias: "/home",
        name: "home",
        component: () => import("@/views/HomeView.vue"),
        meta: {
          title: "Home",
        },
        beforeEnter: (to, from, next) => {
          const isAdmin = store.getters["isAdmin"];
          if (!isAdmin) {
            next();
          } else {
            next("/admin/dashboard");
          }
        },
      },
      {
        path: "/about",
        name: "about",
        component: () => import("@/views/AboutView.vue"),
        meta: {
          title: "About Us",
        },
      },
      {
        path: "/signup",
        component: () => import("@/views/SignUpView.vue"),
        name: "SignUp",
        meta: {
          title: "Sign Up",
        },
      },
      {
        path: "privacy-policy",
        component: () => import("@/views/PrivacyPolicyView.vue"),
        name: "Privacy Policy",
        meta: {
          title: "Privacy Policy",
        },
      },
      {
        path: "/cookie-policy",
        component: () => import("@/views/CookiePolicyView.vue"),
        name: "Cookie Policy",
        meta: {
          title: "Cookie Policy",
        },
      },
      {
        path: "/returns-policy",
        component: () => import("@/views/ReturnsPolicyView.vue"),
        name: "Returns Policy",
        meta: {
          title: "Returns Policy",
        },
      },
      {
        path: "terms-of-use",
        component: () => import("@/views/TermsOfUseView.vue"),
        name: "Terms Of Use",
        meta: {
          title: "Terms Of Use",
        },
      },
      {
        path: "/verify",
        component: () => import("@/views/VerifyView.vue"),
        name: "Verify",
        meta: {
          title: "Verify",
        },
      },
      {
        path: "/unsubscribe",
        component: () => import("@/views/unsubscribeView.vue"),
        name: "Unsubscribe",
        meta: {
          title: "Unsubscribe",
        },
      },
      {
        path: "/product",
        component: () => import("@/views/ProductView.vue"),
        name: "Product",
        meta: {
          title: "View Product",
        },
      },
      {
        path: "/blog",
        component: () => import("@/views/BlogView.vue"),
        name: "Blog",
        meta: {
          title: "Blog",
        },
      },
      {
        path: "/admin/login",
        component: () => import("@/views/admin/AdminLoginView.vue"),
        name: "AdminLogin",
        meta: {
          title: "Admin Login",
        },
      },
      {
        path: "/admin/dashboard",
        component: () => import("@/views/admin/AdminDashboardView.vue"),
        name: "AdminDashboard",
        meta: {
          title: "Admin Dashboard",
          requiresAuth: true,
        },
      },
      {
        path: "/admin/files",
        component: () => import("@/views/admin/FileManagerView.vue"),
        name: "FileManager",
        meta: {
          title: "Admin File Manager",
          requiresAuth: true,
        },
      },
      {
        path: "/admin/products",
        component: () => import("@/views/admin/AdminProductView.vue"),
        name: "AdminProducts",
        meta: {
          title: "Admin Products",
          requiresAuth: true,
        },
      },
      {
        path: "/admin/theme",
        component: () => import("@/views/admin/AdminThemeView.vue"),
        name: "AdminTheme",
        meta: {
          title: "Theme View",
          requiresAuth: true,
        },
      },
      {
        path: "/admin/promos",
        component: () => import("@/views/admin/AdminPromosView.vue"),
        name: "AdminPromos",
        meta: {
          title: "Admin Promos",
          requiresAuth: true,
        },
      },
      {
        path: "/admin/orders",
        component: () => import("@/views/admin/AdminOrdersView.vue"),
        name: "AdminOrders",
        meta: {
          title: "Admin Orders",
          requiresAuth: true,
        },
      },
      {
        path: "/admin/transactions",
        component: () => import("@/views/admin/AdminTransactionsView.vue"),
        name: "AdminTransactions",
        meta: {
          title: "Admin Transactions",
          requiresAuth: true,
        },
      },
      {
        path: "/admin/users",
        component: () => import("@/views/admin/AdminUsersView.vue"),
        name: "AdminUsers",
        meta: {
          title: "Admin Users",
          requiresAuth: true,
        },
      },
      {
        path: "/admin/newsletter",
        component: () => import("@/views/admin/AdminNewsletterView.vue"),
        name: "AdminNewsletter",
        meta: {
          title: "Admin Newsletter",
          requiresAuth: true,
        },
      },
      {
        path: "/admin/logs",
        component: () => import("@/views/admin/AdminLogsView.vue"),
        name: "AdminLogs",
        meta: {
          title: "Admin Logs",
          requiresAuth: true,
        },
      },
      {
        path: "/admin/app-data",
        component: () => import("@/views/admin/AdminAppDataView.vue"),
        name: "AdminAppData",
        meta: {
          title: "Admin App Data",
          requiresAuth: true,
        },
      },
      {
        path: "/admin/deployment",
        component: () => import("@/views/admin/AdminDeploymentView.vue"),
        name: "AdminDeployment",
        meta: {
          title: "Admin Deployment",
          requiresAuth: true,
        },
      },
      {
        path: "/order-successful",
        component: () => import("@/views/OrderPlaced.vue"),
        name: "OrderPlaced",
        meta: {
          title: "OrderPlaced",
          requiresAuth: false,
        },
        beforeEnter: (to, from, next) => {
          // Check if the route has a query parameter named 'id'
          if (to.query.id) {
            // Allow navigation to proceed
            next();
          } else {
            // Redirect the user to the '/home' route
            next("/checkout");
          }
        },
      },
      {
        path: "/order-failed",
        component: () => import("@/views/OrderFailed.vue"),
        name: "OrderFailed",
        meta: {
          title: "OrderFailed",
          requiresAuth: false,
        },
      },
      {
        path: "/cart",
        component: () => import("@/views/CartView.vue"),
        name: "Cart",
        meta: {
          title: "Cart",
          requiresAuth: false,
        },
      },
      {
        path: "/checkout",
        component: () => import("@/views/CheckoutView.vue"),
        name: "Checkout",
        meta: {
          title: "Checkout",
          requiresAuth: false,
        },
      },
      {
        path: "/account",
        component: () => import("@/views/AccountView.vue"),
        name: "Account",
        meta: {
          title: "Account",
          requiresAuth: true,
        },
      },
      {
        path: "/forgot-password",
        component: () => import("@/views/ForgotPasswordView.vue"),
        name: "Forgot Password",
        meta: {
          title: "Forgot Password",
          requiresAuth: false,
        },
      },
      {
        path: "/reset-password",
        component: () => import("@/views/ResetPasswordView.vue"),
        name: "Reset Password",
        meta: {
          title: "Reset Password",
          requiresAuth: false,
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (!store.getters["getAppData"]?.cacheState) {
    store.dispatch("fetchAppData");
  }

  if (to.meta.title) {
    document.title = to.meta.title;
  } else {
    document.title = "Default Title";
  }
  next();

  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  if (requiresAuth && !store.getters["isLoggedIn"]) {
    next({ name: "Login" });
  } else {
    next();
  }
});

export default router;
