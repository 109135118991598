<template>
  <div class="text-center">
    
        <v-list style="max-height: 600px; min-width: 300px; overflow-y: scroll">
          <template v-for="cartItem in cartItems">
            <v-list-item v-bind:key="cartItem.id" @click.prevent="" :ripple="false">
              <router-link :to="`/product?id=${cartItem.id}`">
                <v-img
                  style="width: 103px; height: 170px; margin: 20px 0px"
                  :src="cartItem.imgUrl"
                ></v-img>
              </router-link>

              <v-list-item-content
                style="
                  padding: 0px 17px;
                  margin-top: 10px;
                  align-items: baseline;
                  flex-direction: column;
                  display: flex;
                "
              >
                <h3>{{ cartItem.name }}</h3>
                <br />
                <p>
                  <template v-for="(meta, i) of cartItem.metadataGroups.filter(grp=>!(grp.isOptional && !grp.defaultOptionId))">
                    {{
                      (meta.metadataOptions.find(
                        (o) => o.id == meta.defaultOptionId
                      )?.title ?? "") + (meta.metadataOptions.length != (i + 1) ? "," : "")
                    }}
                  </template>
                </p>

                Quantity:
                <div style="display: flex; min-width:200px;">
                  <v-icon @click="incrementCartItemQty(cartItem.id)" slot="append"
                    style="margin: auto 10px 45px 0px;height: 25px;">
                    mdi-plus
                  </v-icon>
                  <v-text-field
                    label="Qty"
                    single-line
                    @input="changeQty($event, cartItem.id)"
                    :value="cartItem.qty"
                    outlined
                    type="number"
                    :min="0"
                    style="max-width: 60px;"
                  ></v-text-field>
                  <v-icon @click="minusQtyHandler(cartItem)" slot="prepend"
                    style="margin: auto 0px 45px 10px;height: 25px;">
                    mdi-minus
                  </v-icon>
                </div>
                <v-btn
                  text
                  @click.prevent="removeCartItem(cartItem.id)"
                  style="
                    bottom: 10px;
                    position: absolute;
                    text-decoration: underline;
                  "
                  >Remove</v-btn
                >
                <div style="bottom: 20px; right: 25px; position: absolute">
                  ${{
                    (
                      (cartItem.price +
                        selectedMetadataOptionsTotal(cartItem) -
                        (cartItem.price +
                          selectedMetadataOptionsTotal(cartItem)) *
                          cartItem.salePctOff) *
                      cartItem.qty
                    ).toFixed(2)
                  }}
                </div>
              </v-list-item-content>
            </v-list-item>
            <!-- ${{((cartItem.price + selectedMetadataOptionsTotal(cartItem) - ((cartItem.price + selectedMetadataOptionsTotal(cartItem)) * cartItem.salePctOff))).toFixed(2)}} -->
          </template>
        </v-list>

        <div v-if="cartItems.length == 0">
          <p>Your Bag is empty.</p>
          <br>
        </div>
        <v-divider></v-divider>

        <div  v-if="cartItems.length > 0" style="text-align: right; padding: 5px 10px 0px">
          Subtotal: {{ subtotal }}
        </div>
      
        <v-card-actions v-if="cartItems.length > 0">
          <v-spacer></v-spacer>

          <v-btn block color="primary" @click="menu = false"
            :class="{'theme--dark': themedTextColor('primary')}"
          > Checkout </v-btn>
        </v-card-actions>
        Accepted Payment Methods
        <br />
        <img
          src="../assets/img/payment-methods.png"
          style="height: 50px; width: 250px"
        />
  </div>
</template>
<script>
import { mapState, mapGetters, mapMutations } from "vuex";
export default {
  props: ["title", "handlePlus"],
  data() {
    return {
      collapsed: false,
      fav: true,
      menu: false,
      message: false,
      hints: true,
    };
  },
  computed: {
    ...mapState({
      appData: (state) => state.appData,
    }),
    ...mapGetters({
      numberOfCartItems: "getNumberOfCartItems",
      cartItems: "getCartItems",
    }),
    subtotal() {
      let total = 0;
      this.cartItems.forEach((cartItem) => {
        const itemTotal =
          (cartItem.price +
            this.selectedMetadataOptionsTotal(cartItem) -
            (cartItem.price + this.selectedMetadataOptionsTotal(cartItem)) *
              cartItem.salePctOff) *
          cartItem.qty;
        total += parseFloat(itemTotal.toFixed(2));
      });
      return total.toFixed(2);
    },
  },
  methods: {
    ...mapMutations([
      "incrementCartItemQty",
      "decrementCartItemQty",
      "addCartItem",
      "removeCartItem",
    ]),
    minusQtyHandler(cartItem){
      if(cartItem.qty>0) this.decrementCartItemQty(cartItem.id)
      if(cartItem.qty == 0) this.removeCartItem(cartItem.id)
    },
    selectedMetadataOptionsTotal(cartItem) {
      let total = 0;
      cartItem?.metadataGroups?.forEach((meta) => {
        total += parseFloat(
          meta.metadataOptions.find(
            (option) => option.id == meta.defaultOptionId
          )?.price ?? 0
        );
      });
      return parseFloat(total.toFixed(2));
    },
    changeQty(newQty, cartItemId) {
      //not working yet
      let cartItem = this.cartItems.find((ci) => ci.id == cartItemId);
      if (newQty == 0) {
        return this.removeCartItem(cartItemId);
      }
      if (newQty > cartItem.qty) {
        while (cartItem.qty != newQty) {
          this.incrementCartItemQty(cartItemId);
        }
      } else if (newQty < cartItem.qty) {
        while (cartItem.qty != newQty) {
          this.decrementCartItemQty(cartItemId);
        }
      }
    },
    isDarkTheme() {
      return this.$vuetify.theme.dark;
    },
    themedTextColor(colorTheme){
      const darkTheme = this.isDarkTheme();
      let override = colorTheme == 'primary' ? 'invertPrimaryText' : 'invertSecondaryText' 
      const invertOverride = this.appData.theme[darkTheme ? 'dark' : 'light'][override];

      return invertOverride
        ? darkTheme
          ? false
          : true
        : darkTheme
          ? true
          : false
    },
  },
};
</script>